@import '../../variables.scss';

.head {
  width: 100%;
  display: flex;
  height: 60px;
  background-color: #fff;
  padding-left: 10px;
  box-shadow: 2px 2px 8px 2px rgba($primary-text-color, 0.1);

  @media screen and (min-width: $tablet) {
    height: 80px;
  }

  @media screen and (min-width: $desktop) {
    height: 100px;
  }

  &.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 90;
    background-color: rgba(#fff, 0.95);
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-around;
}

.link {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: $accent-color;
  padding: 5px;
  margin-right: 10px;
  transition: transform 350ms ease-in-out, box-shadow 350ms ease-in-out; /* Change transition time to 500ms */

  &:hover {
    transform: scale(1.02);
    box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
  }

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    stroke: $accent-color;
    fill: rgba($accent-color, 1);

    @media screen and (min-width: $desktop) {
      width: 24px;
      height: 24px;
    }
  }

  @media screen and (min-width: $desktop) {
    font-size: 16px;
  }
}

.enter {
  stroke: rgba($accent-color, 1);
}

.home {
  margin-right: auto;
  width: 30px;
  height: 30px;
  transform: scale(1);
  transition: transform 350ms ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  & > svg {
    display: block;
    animation: rotateLogo 6s linear infinite;
  }

  @media screen and (min-width: $tablet) {
    width: 40px;
    height: 40px;
  }

  @media screen and (min-width: $desktop) {
    width: 50px;
    height: 50px;
  }
}

.profile {
  display: flex;
  gap: 10px;
  // margin-left: auto;
}

@keyframes rotateLogo {
  0% {
    transform: rotateY(0deg);
  }
  8.33% {
    /* 1 second of rotation in a 6 seconds cycle */
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
