@import '../../variables.scss';

.wrapper {
  position: relative;
  width: 80px;
  margin-right: auto;
  margin-bottom: 10px;
}

.btn {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-radius: 4px;
  background-color: rgba($primary-text-color, 0.1);

  & > img {
    display: block;
    width: 30px;
    height: 20px;
  }

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: $accent-color;
  }
}

.icon {
  width: 20px;
  height: 16px;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 99;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba($primary-text-color, 0.1);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  pointer-events: none;

  &.shown {
    pointer-events: all;
    opacity: 1;
  }
}

.option {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid rgba($primary-text-color, 0.1);

  & > img {
    border: 1px solid rgba($primary-text-color, 0.1);
    display: block;
    width: 30px;
    height: 20px;
  }
}
