@import '../../variables.scss';

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  & > svg {
    width: 50px;
    height: 50px;

    @media screen and (min-width: $tablet) {
      width: 60px;
      height: 60px;
    }

    @media screen and (min-width: $desktop) {
      width: 70px;
      height: 70px;
    }
  }
}

.text {
  font-size: 14px;
  color: $secondary-text-color;
  text-align: center;
  line-height: 1.4;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
    line-height: 1.5;
  }

  @media screen and (min-width: $desktop) {
    line-height: 1.6;
  }
}

.link {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  color: $accent-color;
  margin-bottom: 20px;
  text-decoration: underline;
  text-decoration-skip-ink: none;

  & > svg {
    fill: $accent-color;
    width: 10px;
    height: 10px;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  border-radius: 10px;
  font-size: 12px;
  // box-shadow: 2px 2px 4px 2px rgba($color: #000000, $alpha: 0.2);
  // background-color: #fff;

  @media screen and (min-width: $tablet) {
    padding-bottom: 30px;
    width: 60%;
    font-size: 14px;
  }

  @media screen and (min-width: $desktop) {
    font-size: 16px;
    width: 40%;
  }
}

.name {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  color: $accent-color;
  margin-bottom: 10px;
}

.info {
  font-size: 16px;
  text-align: center;
  line-height: 1.6;
  margin-bottom: 30px;
  margin-top: 10px;
}

.error {
  font-size: 10px;
  color: red;
}
