@import '../../variables.scss';

.list {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: $tablet) {
    width: 60%;
    gap: 15px;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.header {
  margin-bottom: 15px;
  color: $accent-color;
}

.notify {
  display: block;
  font-size: 13px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  color: $error;
  background-color: rgba($error, 0.1);
  padding: 5px;
  border-radius: 6px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: rgba($secondary-text-color, 0.5);
}

.item {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 2px rgba($primary-text-color, $alpha: 0.1);
}

.link {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: $primary-text-color;
}

.adress {
  display: flex;
  gap: 10px;

  & > li {
    font-size: 14px;
    color: $error;
  }
}

.location {
  display: flex;
  align-items: center;
  gap: 5px;

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: $secondary-text-color;
  }
}

.customers {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  color: $error;

  & > svg {
    fill: $secondary-text-color;
    width: 20px;
    height: 20px;
  }
}

.absent {
  font-size: 16px;
  margin-bottom: 15px;
  color: rgba($primary-text-color, 0.7);
}
