@import '../../variables.scss';

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 10px;
  height: 100%;

  @media screen and (min-width: $mobile) {
    max-width: 480px;
    min-width: 480px;
  }

  @media screen and (min-width: $tablet) {
    max-width: 768px;
    min-width: 768px;
    padding: 20px 10px 20px 10px;
  }

  @media screen and (min-width: $desktop) {
    min-width: 1200px;
    max-width: 1200px;
    padding: 30px 0 20px 0;
  }
}
