@import '../variables.scss';

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.header {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  color: $secondary-text-color;
}

.title {
  font-size: 14px;
  text-align: right;
  color: rgba($secondary-text-color, 0.8);
}

.date {
  font-size: 12px;
  text-align: right;
  margin-bottom: 15px;
  color: $error;
}

.item {
  display: flex;
  //   padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid rgba($primary-text-color, 0.1);
}

.valueTitle {
  font-size: 14px;
  color: $primary-text-color;
  padding: 10px;
}

.value {
  text-align: right;
  font-size: 16px;
  font-weight: 900;
  color: $error;
  padding: 10px;
  //   background-color: #b4b6b9;
}
