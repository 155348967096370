@import '../variables.scss';

.container {
  width: 100%;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
}

.header {
  font-weight: 900;
  text-align: center;
  margin-bottom: 10px;
  color: $secondary-text-color;
}

.title {
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  color: $error;
}

.wrapper {
  width: 100%;
}
