@import '../../variables.scss';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 15px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: transparent;
  background-color: transparent;
  border: none;
  outline: none;

  & > svg {
    display: block;
    stroke: $accent-color;
  }

  &.selected {
    color: $accent-color;
  }

  @media screen and (min-width: $desktop) {
    width: 30px;
    height: 30px;
  }
}
