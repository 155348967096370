@import '../variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  padding: 10px;

  @media screen and (min-width: $tablet) {
    width: 80%;
  }

  @media screen and (min-width: $desktop) {
    width: 60%;
  }
}

.img {
  display: block;
  width: 100%;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;

  @media screen and (min-width: $tablet) {
    height: 400px;
  }

  @media screen and (min-width: $desktop) {
    height: 500px;
  }
}

.header {
  width: fit-content;
  text-align: center;
  line-height: 1.4;
  margin: 0;
  // font-family: 'Literata';
  // margin-left: 15px;
  // margin-right: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  color: rgba($secondary-text-color, 1);
}

.head {
  // font-family: 'Literata';
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.4;
  text-transform: uppercase;
  color: $secondary-text-color;
}

.action {
  width: 100%;
  margin-right: auto;
  text-align: center;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 700;
  margin-top: 20px;
  color: rgba($error, 1);

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }

  @media screen and (min-width: $desktop) {
    font-size: 18px;
  }
}

.create {
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  gap: 10px;
  font-size: 14px;
  text-align: justify;
  margin-right: auto;
  line-height: 1.3;
  color: $primary-text-color;

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
  }

  & > span {
    color: $error;
  }

  @media screen and (min-width: $desktop) {
    font-size: 16px;
  }
}

.highligth {
  display: block;
  width: 100%;
  padding: 5px;
  padding-left: 30px;
  font-size: 14px;
  color: rgba($secondary-text-color, 0.8);
}

.advantage {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
  text-align: justify;
  line-height: 1.3;
  color: rgba($secondary-text-color, 0.7);
  background-color: rgba($accent-color, 0.1);

  @media screen and (min-width: $desktop) {
    font-size: 16px;
  }
}

.stats {
  display: block;
  padding: 5px 0 0 0;
  font-size: 14px;
  line-height: 1.3;
}

.client {
  display: block;
  font-weight: 900;
  font-size: 14px;
  margin-top: 10px;
  color: rgba($secondary-text-color, 0.7);
}

.point {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 0;
  gap: 10px;

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
  }
}

.icon {
  display: block;
  width: fit-content;
  max-width: 100%;
  height: 160px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  text-align: left;
  margin-bottom: 10px;
  color: $accent-color;
}

.text {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  color: rgba($secondary-text-color, 0.5);
  line-height: 1.5;
  letter-spacing: 0.5;
  font-size: 14px;
  text-align: justify;
  border-radius: 10px 20px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
  background-color: #fff;

  @media screen and (min-width: $tablet) {
    width: 60%;
    padding: 20px;
  }

  @media screen and (min-width: $desktop) {
    width: 50%;
  }
}

.highlight {
  font-weight: 600;
  color: $secondary-text-color;
}

.check {
  width: 100%;
  font-size: 14px;
  margin-left: auto;
  line-height: 1.3;
  text-align: justify;
  padding: 15px;
  border-radius: 4px;
  color: $error;
  background-color: rgba($error, 0.1);

  @media screen and (min-width: $desktop) {
    font-size: 16px;
  }
}

.register {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  color: $accent-color;
  transform: scale(1);
  transition: transform 250ms ease-in-out;

  & > svg {
    display: block;
    width: 24px;
    height: 20px;
    fill: currentColor;
  }

  &:hover {
    transform: scale(1.02);
  }

  @media screen and (min-width: $desktop) {
    font-size: 16px;
  }
}

.skeleton {
  width: 100%;
  height: 300px;

  @media screen and (min-width: $tablet) {
    height: 400px;
  }

  @media screen and (min-width: $desktop) {
    height: 500px;
  }
}
