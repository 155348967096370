@import '../../variables.scss';

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  transform: scaleY(1); // Initially shown
  transition: transform 250ms ease-in-out;

  &.isHidden {
    // Hide class
    transform: scaleY(0);
    display: none;
  }
}

.btn {
  display: block;
  width: 22px;
  height: 22px;
  margin-left: auto;
  transition: transform 250ms ease-in-out;

  & > svg {
    fill: rgba($primary-text-color, 1);
  }

  &:hover {
    transform: scale(1.05);
  }
}
.list {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.item {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: rgba($primary-text-color, 1);
  text-align: center;
  // font-weight: 900;
  padding: 30px 15px;
  border-bottom: 1px solid rgba($primary-text-color, 0.2);
  transition: transform 250ms ease-in-out;

  &:hover {
    transform: scale(1.01);
  }

  & > svg {
    display: block;
    width: 30px;
    height: 30px;
    fill: $accent-color;
    stroke: #fff;
  }
}

.icon {
  display: block;
  margin-left: auto;
}

.notify {
  font-size: 14px;
  color: rgba($secondary-text-color, 0.5);
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-radius: 20px;
  background-color: $bg-color;

  & > svg {
    display: block;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    stroke: rgba($secondary-text-color, 0.5);
  }

  @media screen and (min-width: $desktop) {
    padding: 20px;
  }
}
