@import '../variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  padding-top: 50px;

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.wrapper {
  width: 100%;
}

.link {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  color: $accent-color;
  transition: text-decoration 250ms ease-in-out;

  &:hover {
    text-decoration: underline;
  }
}

.error {
  font-size: 12px;
  color: $error;
  margin-top: 5px;
}

.title {
  display: block;
  font-size: 16px;
  margin-bottom: 15px;
  color: $primary-text-color;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 10px;
  color: $primary-text-color;
}

.condition {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: $primary-text-color;

  & > svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: $primary-text-color;
  }
}

.name {
  margin-bottom: 5px;
}
