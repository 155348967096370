.list {
  width: fit-content;
  display: flex;
  gap: 3px;
  align-items: center;
  padding: 10px 0;
}

.item {
  display: block;

  & > svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: #ffab17;
  }
}
