@import '../variables.scss';

.notify {
  font-size: 14px;
  width: fit-content;
  text-align: center;
  margin-bottom: 10px;
  color: $error;
  padding: 5px;
  border-bottom: 2px solid $error;
}
