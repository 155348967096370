@import '../../variables.scss';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
}

.btn {
  padding: 3px;
  color: $accent-color;

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
  }
}

.counter {
  display: block;
  height: 100%;
  font-size: 16px;
  color: $error;
}
