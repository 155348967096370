@import '../variables.scss';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 30px;
}

.icon {
  display: block;
  width: 50px;
  height: 50px;
  fill: $primary-text-color;

  @media screen and (min-width: $tablet) {
    width: 60px;
    height: 60px;
  }

  @media screen and (min-width: $desktop) {
    width: 70px;
    height: 70px;
  }
}

.text {
  font-size: 14px;
  color: $accent-color;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}
