@import '../variables.scss';

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.error {
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  font-weight: 700;
  color: $error;
}
