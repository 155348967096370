@import '../../variables.scss';

.container {
  width: fit-content;
  margin-left: auto;
}

.calendar {
  display: block;
  font-size: 14px;
  text-align: center;
  padding: 5px;
  margin-bottom: 20px;
  margin-left: auto;
  background-color: #fff;
  color: $error;
  border-radius: 10px;
  outline: none;
  border: 1px solid $accent-color;
}

.field {
  font-size: 14px;
  padding: 3px 7px;
  margin-bottom: 20px;
  margin-left: auto;
  text-align: center;
  border: 1px solid $accent-color;
  border-radius: 10px;
  color: $error;
}
