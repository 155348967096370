@import '../../variables.scss';

.container {
  position: relative;
}

.wrapper {
  width: 100%;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;

  @media screen and (min-width: $mobile) {
    width: 480px;
  }

  @media screen and (min-width: $tablet) {
    width: 768px;
    padding: 20px 10px 20px 10px;
  }

  @media screen and (min-width: $desktop) {
    width: 1200px;
    padding: 10px 0 20px 0;
  }
}

.foot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  height: fit-content;
  background-color: $accent-color;
}

.contacts {
  font-size: 16px;
  color: rgba($secondary-text-color, 0.8);
}

.cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.link {
  display: block;
  cursor: pointer;

  & > svg {
    display: block;
    width: 26px;
    height: 26px;
  }
}
