@import '../variables.scss';

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
  color: $accent-color;
}
