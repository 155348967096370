@import '../../variables.scss';

.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  padding: 10px 0 20px 0;
}

.text {
  display: block;
  flex-grow: 1;
  text-align: right;
  font-size: 16px;
  color: rgba($accent-color, 1);
}

.title {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  min-width: 70px;
  border: 4px solid #d5d7d7;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #fff;
  cursor: pointer;

  &.checked {
    border: 12px solid #d5d7d7;
    background-color: rgb(246, 249, 250);
  }
}

.btn {
  position: absolute;
  top: 1px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: #d5d7d7;
  border-radius: 50%;

  &.checked {
    top: -7px;
    right: -4px;
    left: auto;
    background-color: $accent-color;
  }
}

.field {
  opacity: 0;
}
