@import '../variables.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.actionTitle {
  text-align: center;
  text-transform: uppercase;
  color: $error;
  margin-bottom: 15px;
  animation: info 4.5s forwards;
}

.actionCondition {
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 15px;
  color: rgba($secondary-text-color, 1);
}

.backdrop {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-color: rgba($secondary-text-color, 0.5);
  transition: opacity 250ms ease-in-out;
  pointer-events: none;

  &.shown {
    opacity: 1;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  height: fit-content;
  padding: 20px;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  pointer-events: all;
  animation: modal 1s ease-in-out;

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.text {
  font-size: 14px;
  line-height: 1.45;
  text-align: justify;
  color: rgba($secondary-text-color, 0.5);

  @media screen and (min-width: $tablet) {
    font-size: 14px;
  }
}

.btn {
  width: fit-content;
  padding: 10px 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  background-color: #1e78ff;
  cursor: pointer;

  @media screen and (min-width: $tablet) {
    font-size: 14px;
    padding: 10px;
  }
}

.reject {
  font-size: 14px;
  padding: 5px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  color: $primary-text-color;

  @media screen and (min-width: $tablet) {
    font-size: 14px;
  }
}

.animate {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  animation: disappear 0.5s ease forwards 4s;
}

.count {
  width: 100%;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  color: $accent-color;
  margin-top: 25px;
  text-align: center;
  animation: info 4.5s forwards;
}

.balanceWrapper {
  width: 100%;
  animation: info 6.5s forwards;
}

.success {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 900;
  color: $accent-color;
  animation: info 4.5s forwards;
}

.shadow {
  display: block;
  width: 40px;
  height: 5px;
  background-color: rgba($secondary-text-color, 0.1);
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  border-radius: 50%;
  transform-origin: left;
  animation: appear 1.5s forwards;
}

.wrapper {
  width: 100%;
  padding: 0 10px;
  animation: info 4.5s forwards;
}

.balance {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
  gap: 5px;
  color: rgba($secondary-text-color, 1);

  & > svg {
    display: block;
    width: 24px;
    height: 24px;
    stroke: rgba($secondary-text-color, 1);
  }
}

.balanceCount {
  font-size: 16px;
  font-weight: 900;
  margin-left: 5px;
  color: $error;
}

.listWrapper {
  position: relative;
  animation: fade 3s ease-in-out;
}

.list {
  padding-top: 40px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
}

.title {
  margin-bottom: 10px;
  font-size: 14px;
  text-align: left;
  color: $primary-text-color;
}

.successTitle {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
  color: #64c404;
}

.icons {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid rgba($primary-text-color, 0.3);
}

.icon {
  & > svg {
    display: block;
    width: 14px;
    height: 14px;
    fill: rgba($error, 1);
    stroke: rgba($error, 1);

    @media screen and (min-width: $tablet) {
      width: 16px;
      height: 16px;
    }
  }
}

.item {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: rgba($secondary-text-color, 0.5);
  border-bottom: 1px solid rgba($primary-text-color, 0.1);

  @media screen and (min-width: $tablet) {
    font-size: 14px;
  }
}

.errorWrapper {
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.errorText {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  line-height: 1.35;
  color: rgba($secondary-text-color, 0.5);

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}

.imageWrapper {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.giftWrapper {
  padding: 20px 0;
}

.error {
  margin-top: 5px;
  font-size: 10px;
  color: $error;

  @media screen and (min-width: $desktop) {
    font-size: 12px;
  }
}

.warningWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  padding: 10px;
  background-color: rgb(246, 249, 250);
  border-radius: 10px;

  & > svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    height: 30px;
    stroke: $error;
  }
}

.warning {
  font-size: 14px;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 10px;
  color: $error;
}

.img {
  display: block;
  width: 100px;
  height: 100px;
}

.header {
  font-size: 18px;
  font-weight: 900;
  margin-top: 20px;
  text-align: center;
  color: $accent-color;
  text-transform: uppercase;
}

.solo {
  padding: 0 10px;
  animation: flip 6s forwards;
}

.receive {
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  color: $error;
}

.codeTitle {
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  color: rgba($secondary-text-color, 0.5);

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}

.shadow {
  display: block;
  width: 50px;
  height: 10px;
  background-color: rgba($primary-text-color, 0.2);
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  border-radius: 50%;
  transform-origin: left;
  animation: appear 1.5s forwards;
}

.title {
  font-size: 16px;
  color: $accent-color;
  opacity: 0;
  margin-top: 5px;
  margin-left: auto;
  text-align: center;
  animation: info 1.5s forwards;
  animation-delay: 1s;
}

.info {
  font-size: 16px;
  text-align: center;
  color: #64c404;
  opacity: 0;
  animation: info 1.5s forwards;
  animation-delay: 1s;
}

.notifyWrapper {
  width: fit-content;
  display: flex;
  gap: 5px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.notify {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
  color: $accent-color;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;

  & > svg {
    display: block;
    width: 30px;
    height: 30px;
  }
}

.text {
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  color: rgba($secondary-text-color, 0.7);
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flip {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(0.5);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes info {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modal {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

.window {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: fit-content;
  padding: 40px 20px;
  border-radius: 0 0 20px 20px;
  box-shadow: 2px 2px 4px 4px rgba($primary-text-color, 0.1);
  background-color: #fff;
  transform: translate(0, -200%);
  transition: transform 550ms ease-in-out;

  &.shown {
    transform: translate(0, 0);
  }
}

.windowTitle {
  display: block;
  font-size: 16px;
  text-align: center;
  color: $secondary-text-color;
}

.skip {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  font-size: 16px;
  text-decoration: underline;
  color: $accent-color;
  cursor: pointer;
}
