@import '../../variables.scss';

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.notify {
  display: block;
  font-size: 13px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  color: $error;
  background-color: rgba($error, 0.1);
  padding: 5px;
  border-radius: 6px;
}

.item {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 2px rgba($color: #000000, $alpha: 0.1);
}

.title {
  font-size: 16px;
  margin-bottom: 10px;
  color: $accent-color;
}

.name {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-end;
  gap: 5px;
  color: $secondary-text-color;

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}

.adress {
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-bottom: 5px;
  color: $error;

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}

.action {
  font-size: 14px;
  color: $primary-text-color;
}

.condition {
  font-size: 12px;
  color: rgba($secondary-text-color, 0.5);
}

.highlight {
  font-weight: 900;
  color: $error;
}

.employee {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  text-align: right;
  color: rgba($secondary-text-color, 0.5);
}

.absent {
  color: rgba($primary-text-color, 0.7);
}

.btn {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 12px;
  padding: 5px;
  margin-top: 15px;
  margin-left: auto;
  color: #fff;
  background-color: $error;
  border-radius: 4px;
  transition: transform 250ms ease-in-out;

  @media screen and (min-width: $tablet) {
    font-size: 13px;
  }

  @media screen and (min-width: $desktop) {
    font-size: 14px;
  }

  & > svg {
    display: block;
    width: 18px;
    height: 18px;
  }

  &:hover {
    transform: scale(1.02);
  }
}
