@import '../../variables.scss';

.section {
  width: 100%;
  opacity: 0.2;
  font-size: 20px;
  transition: opacity 1.5s ease;

  @media screen and (min-width: $mobile) {
    padding: 5px;
  }

  @media screen and (min-width: $tablet) {
    padding: 10px;
  }
}

.visible {
  opacity: 1;
}
