@import '../variables.scss';

.container {
  width: 100%;
  height: 100%;

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.wrapper {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: $bg-color;
  margin-bottom: 20px;
}

.header {
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  color: $secondary-text-color;

  & > span {
    font-weight: 900;
    text-transform: uppercase;
    color: $error;
  }
}

.availableTitle {
  font-size: 16px;
  margin-bottom: 5px;
  color: rgba($secondary-text-color, 0.5);
}

.available {
  display: block;
  font-size: 16px;
  color: $error;
  line-height: 1.3;
}

.date {
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 900;
  text-align: center;
  text-decoration: underline;
  color: rgba($secondary-text-color, 0.5);
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.item {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border-radius: 10px;
  // border: 1px solid $accent-color;
  // background-color: rgba($accent-color, 0.4);
  transform: scale(1);
  transition: transform 250ms ease-in-out, box-shadow 250ms ease-in-out;

  @media screen and (min-width: $tablet) {
    box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
  }
}

.name {
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
  font-weight: 900;
  margin-bottom: 5px;
  color: $secondary-text-color;
}

.pack {
  font-size: 9px;
  font-weight: 400;
  margin-right: 5px;
}

.price {
  display: block;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
  color: $error;
}

.day {
  font-size: 16px;
  font-weight: 400;
  color: $secondary-text-color;
}

.title {
  font-size: 14px;
  text-align: left;
  margin-bottom: 5px;
  color: rgba($primary-text-color, 0.7);
}

.stats {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 16px;
  margin-bottom: 5px;
  color: rgba($secondary-text-color, 0.8);

  & > svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: currentColor;
  }
}

.btn {
  display: block;
  font-size: 16px;
  padding: 10px 15px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  background-color: $accent-color;
  transition: outline 250ms ease-in-out;

  &:hover {
    outline: 2px solid #fff;
  }
}

.notify {
  margin-bottom: 20px;
  font-size: 14px;
  color: $error;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-radius: 20px;
  background-color: $bg-color;

  & > svg {
    display: block;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    stroke: rgba($error, 0.5);
  }
}
