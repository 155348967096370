@import 'modern-normalize/modern-normalize.css';
@import './variables.scss';
@import 'ol/ol.css';

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

body {
  margin: 0;
  min-height: 100vh;
  // font-family: 'Literata';
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  background-color: #fff;

  // @media screen and (min-width: $tablet) {
  //   padding-bottom: 60px;
  // }

  // @media screen and (min-width: $desktop) {
  //   padding-bottom: 0;
  // }
}

h1,
h2,
h2,
h3,
p,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  box-sizing: border-box;
  -webkit-appearance: none; /* for iOS Safari */
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input:focus {
  caret-color: rgba($secondary-text-color, 0.4);
}

.btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-width: 80px;
  border-radius: 22px 44px 22px 44px;
  padding: 15px 20px;
  font-size: 16px;
  color: #fff;
  background-color: $accent-color;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
  transition: transform 250ms ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.link {
  margin-top: 5px;
  margin-bottom: 15px;

  & > svg {
    width: 20px;
    height: 20px;
    fill: $accent-color;
  }

  @media screen and (min-width: $tablet) {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  @media screen and (min-width: $desktop) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.name {
  position: relative;
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  color: $accent-color;
  margin-bottom: 40px;

  @media screen and (min-width: $tablet) {
    margin-bottom: 50px;
  }

  @media screen and (min-width: $desktop) {
    margin-bottom: 60px;
  }
}

.field {
  width: 100%;
  border: 1px solid $accent-color;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  font-size: 16px;
  padding-right: 40px;
  border-radius: 10px;
  color: rgba($secondary-text-color, 0.7);
  background-color: transparent;
  outline: none;

  &:focus {
    box-shadow: 2px -2px 8px 1px rgba($color: #000000, $alpha: 0.1);
  }

  &::placeholder {
    font-size: 12px;
    color: rgba($primary-text-color, 0.5);

    @media screen and (min-width: $tablet) {
      font-size: 14px;
    }
  }

  @media screen and (min-width: $tablet) {
    padding: 15px 10px;
    padding-right: 40px;
  }
}

.title {
  position: absolute;
  top: -10px;
  left: 20px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 10px;
  padding: 2px 5px;
  border: 1px solid rgba($primary-text-color, 0.1);

  @media screen and (min-width: $tablet) {
    font-size: 16px;
    top: -12px;
    left: 20px;
  }
}
