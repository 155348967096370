@import '../../variables.scss';

.wrapper {
  position: relative;
  min-width: 200px;
  width: fit-content;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  color: $accent-color;
}

.btn {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  color: $accent-color;
  font-size: 16px;
  text-align: center;

  & > svg {
    fill: $accent-color;
    width: 24px;
    height: 24px;
  }
}

.list {
  width: fit-content;
  min-width: 200px;
  max-height: 155px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 102%;
  right: 0;
  z-index: 9;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0;
  transform-origin: top;
  transition: opacity 0.25s ease-in-out;
  pointer-events: none;
  border: 1px solid rgba($primary-text-color, 0.1);

  &.show {
    opacity: 1;
    pointer-events: all;
  }
}

.item {
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid rgba($primary-text-color, 0.1);
}

.amount {
  font-size: 14px;
}

.optionBtn {
  display: block;
  width: 100%;
  font-size: 14px;
  color: rgba($secondary-text-color, 0.5);
}

.option {
  width: auto;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  text-align: left;
  padding-left: 35px;
  gap: 15px;

  color: rgba($secondary-text-color, 0.5);
  cursor: pointer;
}

.box {
  opacity: 0;
  &:checked + .checkbox {
    background-image: url('../../icons/ok.svg');
    background-position: center;
    background-size: contain;
  }
}

.checkbox {
  position: absolute;
  left: 5px;
  top: 50%;
  width: 18px;
  height: 16px;
  border: 1px solid #b4bbba;
  background-color: #fff;
  border-radius: 4px;
  transform: translate(0, -50%);
  cursor: pointer;
}
