@import '../../variables.scss';

.container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  height: 100vh;
  padding: 10px;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  border-radius: 10px;
  font-size: 12px;
  // box-shadow: 2px 2px 4px 2px rgba($color: #000000, $alpha: 0.2);
  // background-color: #fff;
  // border: 1px solid #fff;

  @media screen and (min-width: $tablet) {
    padding-bottom: 30px;
    width: 50%;
    font-size: 14px;
  }

  @media screen and (min-width: $desktop) {
    font-size: 16px;
    width: 40%;
  }
}

.header {
  margin-left: auto;
  color: $secondary-text-color;
}

.link {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-right: auto;
  text-decoration: underline;
  color: $accent-color;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}

.error {
  margin-top: 5px;
  font-size: 10px;
  color: $error;

  @media screen and (min-width: $desktop) {
    font-size: 12px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  font-size: 10px;
  color: $primary-text-color;
}

.passBtn {
  position: absolute;
  right: 15px;
  top: 20px;
  color: $secondary-text-color;

  &.shown {
    color: rgba($secondary-text-color, 0.5);
  }

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: currentColor;

    @media screen and (min-width: $tablet) {
      width: 22px;
      height: 22px;
    }

    @media screen and (min-width: $desktop) {
      width: 24px;
      height: 24px;
    }
  }

  @media screen and (min-width: $tablet) {
    right: 15px;
    top: 13px;
  }
}

.passLink {
  font-size: 14px;
  margin-right: auto;
  text-decoration: underline;
  color: $primary-text-color;
  margin-bottom: 15px;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}

.condition {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: $primary-text-color;

  & > svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: $primary-text-color;
  }
}
