@import '../variables.scss';

.wrapper {
  width: 100%;
  padding: 15px 5px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $tablet) {
    width: 60%;
    padding: 20px;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
    padding: 25px;
  }
}

.link {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  margin-left: auto;
  margin-bottom: 20px;
  color: $error;
  transform: scale(1);
  padding: 7px;
  transition: transform 250ms ease-in-out, box-shadow 250ms ease-in-out;

  &:hover {
    transform: scale(1.02);
    box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
  }

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: $error;
  }
}

.header {
  font-size: 14px;
  text-align: right;
  margin-right: 5px;
  color: $primary-text-color;
}

.head {
  margin-bottom: 15px;
  padding-top: 10px;
  text-align: center;
  border-top: 1px solid rgba($primary-text-color, 0.1);
}

.name {
  text-align: center;
  font-size: 14px;
  color: rgba($secondary-text-color, 1);
}

.customer {
  width: 80px;
}

.accent {
  font-weight: 900;
  color: $secondary-text-color;
}

.title {
  font-size: 14px;
  text-align: right;
  margin-bottom: 10px;
  color: $accent-color;
}

.location {
  width: fit-content;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-bottom: 10px;
  font-size: 12px;
  color: rgba($secondary-text-color, 0.7);
  padding: 0 5px;
  padding-bottom: 5px;

  @media screen and (min-width: $tablet) {
    font-size: 14px;
    padding-bottom: 10px;
  }

  & > svg {
    display: block;
    width: 24px;
    height: 24px;
  }
}

.gift {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  text-align: center;
  line-height: 1.3;
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  color: $error;
  background-color: rgba($error, 0.1);

  & > svg {
    display: block;
    width: 30px;
    height: 30px;
    fill: currentColor;
  }
}

.icons {
  display: flex;
  padding: 0 5px;
}

.icon {
  font-size: 10px;
  color: $primary-text-color;

  @media screen and (min-width: $tablet) {
    font-size: 12px;
  }

  &.time {
    margin-left: 35%;

    @media screen and (min-width: $desktop) {
      margin-left: 170px;
    }
  }

  &.amount {
    margin-left: auto;
  }
}

.quantity {
  font-weight: 900;
  width: 20px;
  display: flex;
  justify-content: center;
  color: $primary-text-color;
}

.container {
  position: relative;
  width: 100%;
  margin-top: 30px;
  padding: 20px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
}

.overlay {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(#fff, 0) 0%,
    rgba(#fff, 0.4) 80%,
    rgba(#fff, 0.5) 85%,
    rgba(#fff, 0.6) 90%,
    rgba(#fff, 0.7) 95%,
    rgba(#fff, 0.8) 100%
  );
}

.absent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
  color: rgba($secondary-text-color, 1);
}

.listWrapper {
  position: relative;
}

.icons {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid rgba($primary-text-color, 0.3);
}

.icon {
  & > svg {
    display: block;
    width: 14px;
    height: 14px;
    fill: rgba($error, 1);
    stroke: rgba($error, 1);

    @media screen and (min-width: $tablet) {
      width: 16px;
      height: 16px;
    }
  }
}

.list {
  padding-top: 40px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
}

.item {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding-right: 20px;
  color: rgba($secondary-text-color, 0.8);
  border-bottom: 1px solid rgba($primary-text-color, 0.1);

  @media screen and (min-width: $tablet) {
    font-size: 14px;
  }
}
