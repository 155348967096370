@import '../../variables.scss';

.wrapper {
  position: fixed;
  top: 20px;
  left: 50%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: fit-content;
  min-width: 60%;
  font-size: 14px;
  text-align: center;
  padding: 20px;
  color: #fff;
  background-color: #383839;
  border-radius: 20px;
  border: 2px solid #fff;
  opacity: 0;
  transform: translateX(-50%);
  box-shadow: inset 4px 4px 4px 0 #383839;
  transition: opacity 350ms ease-in-out;

  &.shown {
    opacity: 1;
  }

  @media screen and (min-width: $tablet) {
    min-width: 30%;
  }

  @media screen and (min-width: $desktop) {
    font-size: 16px;
    min-width: 10%;
  }

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    stroke: inherit;
    fill: inherit;
  }
}
