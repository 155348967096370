@import '../../variables.scss';

.container {
  padding: 10px;
}

.title {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  color: rgba($secondary-text-color, 0.5);
}

.wrapper {
  width: 80%;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);

  @media screen and (min-width: $tablet) {
    width: 160px;
    height: 160px;
  }

  @media screen and (min-width: $desktop) {
    width: 160px;
    height: 160px;
  }
}

.warning {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
  line-height: 1.3;
  color: $error;
}

.btn {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  color: $accent-color;
  border: 1px solid $accent-color;
  cursor: pointer;

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
  }
}
