$primary-text-color: #848181;
$secondary-text-color: #000000;
$accent-color: #02e6ca;
$error: #fe3d3d;
$bg-color: #f6f9fa;
// $primary-bg-color: #e7e7e7;
// $secondary-bg-color: #f6f6f6;
// $category-bg-color: #f3f7fb;
// $input-bg-color: #e9f0fd;
$mobile: 480px;
$tablet: 768px;
$desktop: 1200px;
