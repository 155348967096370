@import '../../variables.scss';

.wrapper {
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 50%;
  }
}

.head {
  text-align: center;
  margin-bottom: 10px;
}

.container {
  position: relative;
  width: 100%;
  padding: 5px;
  background-color: transparent;
  margin-bottom: 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 4px 2px rgba($color: #000000, $alpha: 0.1);
}

.title {
  margin-bottom: 10px;
  margin-right: 5px;
  color: rgba($secondary-text-color, 0.5);
  font-size: 14px;
  text-align: right;
}

.overlay {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(#fff, 0) 0%,
    rgba(#fff, 0.5) 80%,
    rgba(#fff, 0.6) 85%,
    rgba(#fff, 0.7) 90%,
    rgba(#fff, 0.8) 95%,
    rgba(#fff, 0.9) 100%
  );
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 170px;
  padding: 10px 0;
  border-radius: 10px;
  overflow-y: scroll;
  background-color: transparent;
  transition: max-height 250ms ease-in-out;

  &.shown {
    max-height: fit-content;
    border: 2px solid #fff;
    background-color: #fff;
  }
}

.item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  border-bottom: 1px solid rgba($primary-text-color, 0.1);
}

.name {
  font-size: 12px;
  color: $secondary-text-color;
}

.adress {
  font-size: 13px;
  width: 20%;
  color: rgba($secondary-text-color, 0.8);
  max-width: 100px;
}

.show {
  display: block;
  font-size: 12px;
  text-decoration: underline;
  margin-left: auto;
  color: $error;
}

.time {
  font-size: 12px;
  margin-right: 12px;
  color: $error;
}

.amount {
  font-size: 14px;
  color: $primary-text-color;
  font-weight: 900;
  width: 20px;
  display: flex;
  justify-content: center;

  & > svg {
    display: block;
    width: 16px;
    height: 16px;
  }
}

.icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.iconTitle {
  font-size: 10px;
  color: $primary-text-color;
}

.stat {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  color: $accent-color;
  transform: scale(1);
  transition: transform 250ms ease-in-out;

  & > svg {
    display: block;
    width: 24px;
    height: 24px;
    fill: $accent-color;
  }

  &:hover {
    transform: scale(1.02);
  }
}

.delete {
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  color: #fff;
  padding: 10px;
  background-color: $error;
  border-radius: 10px;
  transition: transform 250ms ease-in-out, box-shadow 250ms ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 8px 2px rgba($error, 0.4);
  }

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
  }
}

.promoContainer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 10px 5px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
}

.codeTitle {
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  color: rgba($secondary-text-color, 1);
}

.promoWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  line-height: 1.3;
}

.info {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 5px;
  font-size: 12px;
  color: rgba($error, 1);

  & > svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: rgba($error, 1);
    stroke: rgba($error, 1);
  }

  @media screen and (min-width: $tablet) {
    font-size: 14px;
  }
}

.promo {
  display: block;
  width: 100%;
  overflow-x: scroll;
  font-size: 16px;
  text-align: center;
  color: rgba($secondary-text-color, 0.5);
  padding: 15px;
  padding-right: 40px;
  border: 1px solid rgba($primary-text-color, 0.1);
  border-radius: 10px;
  outline: none;
  // box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
}

.copy {
  position: absolute;
  bottom: 12px;
  right: 10px;
  padding: 2px;
  border-radius: 10px;
  transform: scale(1);
  transition: transform 250ms ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: rgba($error, 0.7);
  }
}

.owner {
  font-size: 14px;
  text-align: center;
  line-height: 1.3;
  padding: 10px;
  background-color: rgba($primary-text-color, 0.2);
  border-radius: 4px;
  margin-top: 15px;
  color: $primary-text-color;
}

.absent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
  color: rgba($secondary-text-color, 1);
}
