@import '../variables.scss';

.info {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 15px;
  font-size: 16px;
  text-align: center;
  color: $accent-color;
  animation: appear 1s ease-in-out;

  & > svg {
    display: block;
    width: 100px;
    height: 100px;
    fill: currentColor;
  }

  &.shown {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
