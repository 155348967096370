@import '../../variables.scss';

.wrapper {
  width: 100%;
  padding-top: 15px;
  margin-bottom: auto;
  max-height: fit-content;

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.btn {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  color: $accent-color;

  & > svg {
    display: block;
    width: 24px;
    height: 24px;
    fill: $accent-color;
  }
}

.form {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
  background-color: #fff;
  border: 1px solid hsl(0, 0%, 100%);
  opacity: 0;
  transition: opacity 350ms ease-in-out;

  &.shown {
    opacity: 1;
  }
}

.name {
  position: relative;
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  color: $accent-color;
  margin-bottom: 30px;

  @media screen and (min-width: $tablet) {
    margin-bottom: 40px;
  }
}

.title {
  position: absolute;
  top: -6px;
  left: 20px;
  font-size: 10px;
  background-color: #fff;
  padding: 0 2px;

  @media screen and (min-width: $tablet) {
    font-size: 12px;
    top: -8px;
    left: 20px;
    padding: 0 5px;
  }
}

.error {
  color: $error;
  font-size: 10px;
  margin-top: 5px;
}
