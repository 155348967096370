@import '../variables.scss';

.wrapper {
  width: 100%;
  height: 100vh;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
}

.shadow {
  display: block;
  width: 50px;
  height: 10px;
  background-color: rgba($primary-text-color, 0.2);
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  border-radius: 50%;
  transform-origin: left;
  animation: appear 1.5s forwards;
}

.title {
  font-size: 20px;
  letter-spacing: 1.25px;
  color: #64c404;
  opacity: 0;
  margin-top: 5px;
  text-align: center;
  animation: info 1.5s forwards;
  animation-delay: 1s;
}

.info {
  font-size: 18px;
  color: #64c404;
  opacity: 0;
  animation: info 1.5s forwards;
  animation-delay: 1s;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}

.link {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
  font-size: 16px;
  transform: scale(1);
  color: $accent-color;
  transition: transform 250ms ease-in-out;

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: currentColor;
  }

  &:hover {
    transform: scale(1.02);
  }
}

.codeWrapper {
  opacity: 0;
  animation: info 1.5s forwards;
  animation-delay: 2s;
}

@keyframes appear {
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(0.5);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes info {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.invalid {
  font-size: 14px;
  color: rgba($error, 1);
  text-align: center;
  line-height: 1.25;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}

.codeTitle {
  margin-top: 5px;
  font-size: 16px;
  text-align: center;
  color: rgba($secondary-text-color, 0.5);

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}
