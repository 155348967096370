@import '../../variables.scss';

.wrapper {
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);

  @media screen and (min-width: $tablet) {
    width: 80%;
  }

  @media screen and (min-width: $desktop) {
    width: 50%;
  }
}

.head {
  text-align: center;
  margin-bottom: 10px;
  color: $secondary-text-color;
}

.title {
  font-size: 14px;
  text-align: center;
  color: $primary-text-color;
}

.codeWrapper {
  width: 100%;
  padding-top: 10px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  color: $accent-color;
  border: 1px solid $accent-color;
}

.warning {
  font-size: 12px;
  text-align: justify;
  margin-top: 20px;
  color: $error;
}
