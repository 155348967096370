@import '../../variables.scss';

.header {
  font-size: 14px;
  color: $accent-color;
  margin-bottom: 10px;

  @media screen and (min-width: $tablet) {
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: $tablet) {
    width: 60%;
    gap: 15px;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
    gap: 25px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
}

.title {
  font-size: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $secondary-text-color;
  font-weight: 900;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}

.location {
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  gap: 3px;
  color: rgba($secondary-text-color, 1);

  & > svg {
    width: 20px;
    height: 20px;
    fill: $error;
  }
}

.promoWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  color: $secondary-text-color;
}

.token {
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 5px;
  margin-left: auto;
  color: $primary-text-color;

  @media screen and (min-width: $tablet) {
    font-size: 14px;
  }
}

.warning {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  width: 100%;
  height: 150px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: rgba($error, 0.2);
  font-size: 12px;
  margin-bottom: 20px;
  line-height: 1.3;
  color: $error;
  text-align: justify;
  overflow: hidden;
  transition: height 0.5s ease-in-out;

  & > svg {
    display: block;
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    fill: currentColor;
  }

  @media screen and (min-width: $tablet) {
    width: 60%;
    height: 220px;
    font-size: 14px;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }

  &.hidden {
    padding: 0;
    height: 0;
  }
}

.info {
  font-size: 12px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px;
  color: $error;

  & > svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }

  @media screen and (min-width: $tablet) {
    font-size: 14px;
  }
}

.btn {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 12px;
  padding: 5px;
  margin-top: 35px;
  margin-left: auto;
  color: #fff;
  background-color: $accent-color;
  border-radius: 4px;
  transition: transform 250ms ease-in-out;

  @media screen and (min-width: $tablet) {
    font-size: 13px;
  }

  @media screen and (min-width: $desktop) {
    font-size: 14px;
  }

  & > svg {
    display: block;
    width: 18px;
    height: 18px;
  }

  &.delete {
    color: #fff;
    background-color: $error;
  }

  &.info {
    margin-top: 5px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    transform: scale(1.02);
  }
}

.btnWrapper {
  display: flex;
  gap: 10px;
  width: fit-content;
  margin-left: auto;
}

.promo {
  display: block;
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: rgba($secondary-text-color, 0.5);
  padding: 15px;
  padding-right: 40px;
  border: none;
  border-radius: 10px;
  outline: none;
  background-color: $bg-color;
}

.copy {
  position: absolute;
  bottom: 8px;
  right: 10px;
  padding: 5px;
  border-radius: 10px;
  transition: background-color 450ms ease-in-out;

  &:hover {
    background-color: rgba(#dad3d3, 0.3);
  }

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: rgba($error, 0.7);
  }

  @media screen and (min-width: $desktop) {
    bottom: 10px;
    right: 10px;
  }
}

.absent {
  color: rgba($primary-text-color, 0.7);
}

.inactive {
  font-size: 14px;
  text-align: center;
  color: $primary-text-color;
}
