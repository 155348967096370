@import '../../variables.scss';

.btn {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  margin-top: 20px;
  color: $accent-color;
  transition: transform 250ms ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
  }

  & > svg {
    display: block;
    width: 30px;
    height: 30px;
    fill: $accent-color;
  }
}

.list {
  display: none;
  width: 100%;
  flex-direction: column;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;

  &.shown {
    display: flex;
    animation: showList 0.5s forwards;
  }

  &.hidden {
    animation: hideList 0.5s forwards;
  }
}

@keyframes showList {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes hideList {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}

.item {
  position: relative;
  min-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 20px;
  background-color: rgb(246, 249, 250);
}

.spot {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 12px;
  color: $error;
  display: flex;
  width: fit-content;
  gap: 3px;
  align-items: center;

  & > svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: $error;
  }
}

.customer {
  display: flex;
  gap: 2px;
  align-items: center;
  font-size: 12px;
  color: rgba($secondary-text-color, 0.4);

  & > svg {
    display: block;
    width: 14px;
    height: 14px;
  }
}

.text {
  margin-top: 10px;
  font-size: 16px;
  color: $primary-text-color;
}

.time {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 10px;
  color: $error;
}
