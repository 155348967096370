@import '../../variables.scss';

.wrapper {
  width: 100%;
  margin-top: 20px;
  margin-bottom: auto;
  max-height: fit-content;

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.btn {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  color: $accent-color;
  transition: transform 250ms ease-in-out;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }

  & > svg {
    display: block;
    width: 24px;
    height: 24px;
    fill: $accent-color;
  }

  &:hover {
    transform: scale(1.02);
  }
}

.form {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
  background-color: #fff;
  border: 1px solid hsl(0, 0%, 100%);
  opacity: 0;
  transition: opacity 350ms ease-in-out;

  &.shown {
    opacity: 1;
  }
}

.name {
  position: relative;
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  color: $accent-color;
  margin-bottom: 30px;

  @media screen and (min-width: $tablet) {
    margin-bottom: 40px;
  }
}

.title {
  position: absolute;
  top: -6px;
  left: 20px;
  font-size: 16px;
  background-color: #fff;
  padding: 0 2px;

  @media screen and (min-width: $tablet) {
    font-size: 12px;
    top: -8px;
    left: 20px;
    padding: 0 5px;
  }
}

.select {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: rgba($secondary-text-color, 0.5);
  font-size: 16px;
  text-align: right;
  margin-bottom: 10px;
}

.absent {
  margin-left: auto;
  margin-right: auto;
  color: $error;
  font-size: 16px;
}

.link {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  text-transform: uppercase;
  color: $accent-color;
  transform: scale(1);
  transition: transform 550ms ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  & > svg {
    display: block;
    width: 24px;
    height: 24px;
    fill: $accent-color;
  }
}

.error {
  color: $error;
  font-size: 10px;
  margin-top: 5px;
}

.edit {
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 2px;
  color: rgba($error, 0.5);
  transition: color 250ms ease-in-out;

  &:hover {
    color: rgba($error, 1);
  }

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}

.notify {
  font-size: 13px;
  margin-top: 10px;
  color: $error;
  text-decoration: underline;
  text-align: right;
  margin-bottom: 10px;
  opacity: 0;
  transition: opacity 350ms ease-in-out;

  &.shown {
    opacity: 1;
  }
}

.warning {
  font-size: 16px;
  text-align: center;
  padding: 5px 0;
  margin-bottom: 15px;
  color: $error;
  opacity: 0;
  transition: opacity 750ms ease-in-out;

  &.shown {
    opacity: 1;
  }
}
