@import '../variables.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  background-color: #fff;

  @media screen and (min-width: $tablet) {
    width: 60%;
  }

  @media screen and (min-width: $desktop) {
    width: 40%;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.title {
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 10px;
  color: $accent-color;
  animation: appear 2s ease-in-out;
}

.info {
  font-size: 14px;
  color: $primary-text-color;
  line-height: 1.5;
  text-align: center;
  animation: appear 3s ease-in-out;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}

.btn {
  animation: appear 3s ease-in-out;
}

.error {
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  color: $secondary-text-color;
  animation: appear 5s ease-in-out;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
