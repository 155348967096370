@import '../../variables.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scaleY(1);
  transform-origin: top;
  background-color: rgba($secondary-text-color, 0.3);
  pointer-events: all;

  &.shown {
    transform: scaleY(0);
  }
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  width: 90%;
  height: fit-content;
  border-radius: 10px;
  background-color: #fff;
  opacity: 0;
  transition: opacity 350ms ease-in-out;

  &.shown {
    opacity: 1;
  }

  @media screen and (min-width: $tablet) {
    width: 40%;
  }

  @media screen and (min-width: $desktop) {
    width: 30%;
  }
}

.text {
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  color: $primary-text-color;
}

.btn {
  display: flex;
  min-width: 80px;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 15px 20px;
  border-radius: 44px 22px;
  background-color: $error;
  box-shadow: 2px 2px 8px 2px rgba($color: #000000, $alpha: 0.1);
  transition: transform 250ms ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.btnWrapper {
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 20px;
}
